import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: localStorage.getItem('huaren-token')||'',
    carNumber: 0,//公共头采购车数量
    orderNumber: 0,//公共头订单数量
    note: [],//消息
    invoiceType:[],//发票类型
    memberInfo:0,//客户信息
    roleTitle:'',//客户信息
    busyMemberInfo:{}
  },
  mutations: {
    // 退出登录
    quit(state) {
      state.token = ''
    },
    setStoreToken(state, data) {
      state.token = data
      console.log(state.token);
    },
    // 更改采购车数量
    setCarNumber(state, data) {
      state.carNumber = data
    },
    // 更改订单数量
    setOrderNumber(state, data) {
      state.orderNumber = data
    },
    // 留言
    setNote(state, val) {
      console.log(val,'sadaaa');
      state.note[val.index]=val
    },
    // 用户信息
    setMember(state, val) {
      console.log(val);
      state.memberInfo = val
    },
    // 业务员信息
    setBusyMember(state, val) {
      console.log(val);
      state.busyMemberInfo = val
    },
    setRoleTitle(state, val) {
      console.log(val);
      state.roleTitle = val
    },
    // 更改发票类型
    setInvoce(state, val) {
      console.log(val);
      // state.invoiceType = val
      state.invoiceType[val.index]=val
    },
  },
  actions: {
  },
  modules: {
  },
  getters: {
    getCarNumber: state => state.carNumber,
    getOrderNumber: state => state.orderNumber
  }
})
